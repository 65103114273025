import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faSearch,
  faCheck,
  faEnvelope,
  faArrowsRotate,
  faGear,
  faBookOpen,
  faInfoCircle,
  faXmark,
  faPlay,
  faShieldHalved,
  faMinus,
  faPlus,
  faStop,
  faCheckSquare,
  faBell,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faGoogle,
  faEbay,
  faChrome,
  faRaspberryPi,
  faAndroid,
} from "@fortawesome/free-brands-svg-icons";

export const FontAwesome = {
  Check: <FontAwesomeIcon icon={faCheck} />,
  CheckOn: <FontAwesomeIcon icon={faCheckSquare} />,
  Search: <FontAwesomeIcon icon={faSearch} />,
  Envelope: <FontAwesomeIcon icon={faEnvelope} />,
  Reset: <FontAwesomeIcon icon={faArrowsRotate} />,
  Gear: <FontAwesomeIcon icon={faGear} />,
  Book: <FontAwesomeIcon icon={faBookOpen} />,
  Info: <FontAwesomeIcon icon={faInfoCircle} />,
  Close: <FontAwesomeIcon icon={faXmark} />,
  Play: <FontAwesomeIcon icon={faPlay} />,
  Shield: <FontAwesomeIcon icon={faShieldHalved} />,
  Github: <FontAwesomeIcon icon={faGithub} />,
  Minus: <FontAwesomeIcon icon={faMinus} />,
  Plus: <FontAwesomeIcon icon={faPlus} />,
  Stop: <FontAwesomeIcon icon={faStop} />,
  Google: <FontAwesomeIcon icon={faGoogle} />,
  Bell: <FontAwesomeIcon icon={faBell} />,
  Plug: <FontAwesomeIcon icon={faPlug} />,
  Ebay: <FontAwesomeIcon icon={faEbay} />,
  Chrome: <FontAwesomeIcon icon={faChrome} />,
  RPi: <FontAwesomeIcon icon={faRaspberryPi} />,
  Android: <FontAwesomeIcon icon={faAndroid} />,
  Pause: <FontAwesomeIcon icon={faPause} />,
};
