export const HijriMonths = [
  "Muharram",
  "Safar",
  "Rabi Al-Awwal",
  "Rabi Al-Thani",
  "Jamada Al-Awwal",
  "Jamada Al-Thani",
  "Rajab",
  "Sha'ban",
  "Ramadan",
  "Shawwal",
  "Dhul Qa'dah",
  "Dhul Hijjah",
];
export const Hours = [...new Array(12)];
export const Minutes = [...new Array(60)];
