export const DefaultSettings = {
  locationSettings: {
    address: "Al-Masjid An-Nabawi",
    timeZoneID: "Asia/Riyadh",
    lat: "24.4672105",
    lng: "39.611131",
  } /* صلى الله عليه وعلى آله وسلم */,
  calculationSettings: { method: "ISNA", asrMethod: "S" },
  deviceSettings: { azanCallsEnabled: "Y", mode: "N" },
  azanSettings: { fajr: "13", dhuhr: "7", asr: "9", maghrib: "6", isha: "3" },
  offsetSettings: { fajr: 0, dhuhr: 0, asr: 0, maghrib: 0, isha: 0 },
  alarmSettings: [],
  naflAlarmSettings: [],
  settingsVersion: 12,
};
