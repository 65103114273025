import fajr0 from "../images/bg/fajr/0.jpg";
import fajr1 from "../images/bg/fajr/1.jpg";
import fajr2 from "../images/bg/fajr/2.jpg";
import sunrise0 from "../images/bg/sunrise/0.jpg";
import sunrise1 from "../images/bg/sunrise/1.jpg";
import sunrise2 from "../images/bg/sunrise/2.jpg";
import dhuhr0 from "../images/bg/dhuhr/0.jpg";
import dhuhr1 from "../images/bg/dhuhr/1.jpg";
import dhuhr2 from "../images/bg/dhuhr/2.jpg";
import asr0 from "../images/bg/asr/0.jpg";
import asr1 from "../images/bg/asr/1.jpg";
import asr2 from "../images/bg/asr/2.jpg";
import maghrib0 from "../images/bg/maghrib/0.jpg";
import maghrib1 from "../images/bg/maghrib/1.jpg";
import maghrib2 from "../images/bg/maghrib/2.jpg";
import isha0 from "../images/bg/isha/0.jpg";
import isha1 from "../images/bg/isha/1.jpg";
import isha2 from "../images/bg/isha/2.jpg";

export const Backgrounds = {
  fajr0: fajr0,
  fajr1: fajr1,
  fajr2: fajr2,
  sunrise0: sunrise0,
  sunrise1: sunrise1,
  sunrise2: sunrise2,
  dhuhr0: dhuhr0,
  dhuhr1: dhuhr1,
  dhuhr2: dhuhr2,
  asr0: asr0,
  asr1: asr1,
  asr2: asr2,
  maghrib0: maghrib0,
  maghrib1: maghrib1,
  maghrib2: maghrib2,
  isha0: isha0,
  isha1: isha1,
  isha2: isha2,
};
